import {
  SIDE_KEYWORD,
  VERTEX_KEYWORD,
  SIDE_VERTICES_MAP,
  SIDE_DIMENSION_MAP,
  FLOAT_POINT_ADJUST,
  PLUS_SIGN_DISTANCE,
  ALIGNMENT_MAP,
  NEXT_SEAT_SIDES,
  PREVIOUS_SEAT_SIDES,
  PILLOW_CONFIG_MAP,
  SIDE_CONFIG_MAP,
  OPPOSITE_SEAT_SIDES,
} from '../constants';
import {
  getLocalKeyword,
  getWorldKeyword,
  polygonIntersect,
  getAlignKeyword,
  mapRotationInRange,
} from '../utils';

import { getRootId } from '../threekitUtils';
import { status } from '../../status';
/**
 * The item class is the base class for creating sactional piece class
 */

export default class Item {
  constructor(threekitApi, options = {}) {
    const { translation, rotation, testData, island } = options;
    this._testData = testData;
    this._threekitApi = threekitApi;
    this._id = null;
    this._configurator = null;
    this._type = 'item';
    this._index = -1;
    this.pillows = [];

    this.translation = translation || { x: 0, y: 0, z: 0 };
    this.rotation = mapRotationInRange(rotation);
    this.island = island;

    this.connectors = {};

    this._validOptions(options);
  }

  // this should be add to all public API to make sure the init are called before access public API
  _initialCheck = () => {
    if (!this._id) {
      throw new Error(
        'Class must be initialed before access threekit data like configurator/instanceId, please call and await init() first'
      );
    }
  };

  _disconnect = () => {
    for (const side of SIDE_KEYWORD) {
      if (this.hasOwnProperty(side) && this[side]) {
        // clean the connected item reference
        const targetItem = this[side];

        const targetLocalSide = SIDE_KEYWORD.find(
          (targetSide) => targetItem[targetSide] === this
        );

        targetItem[targetLocalSide] = null;
        targetItem[getAlignKeyword(targetLocalSide)] = null;
        targetItem._updatePillow();

        // clean this item reference
        this[side] = null;
        this[getAlignKeyword(side)] = null;
      }
    }
    this._updatePillow();
    this.island = null;
  };

  _updatePillow = (options = {}) => {
    if (this._type !== 'seat' || !this._id) return;
    // reset pillows
    this.pillows = [];

    const { isCorner, noDeepPillow } = options;

    const sideWithSideItem = SIDE_KEYWORD.filter(
      (side) => this[side] && this[side]._type === 'side'
    );
    let configVal;

    if (sideWithSideItem.length === 4) {
      // four side are all connect to side, not a usual setup
      // will simply add the pillow to the world top side
      configVal = this.getLocalSide('top');
    } else if (sideWithSideItem.length === 3) {
      // when 3 sides are connected with side, add pillow to the side which the opposite connection is null or is not a side
      configVal = SIDE_KEYWORD.find(
        (side) =>
          !this[OPPOSITE_SEAT_SIDES[side]] ||
          this[OPPOSITE_SEAT_SIDES[side]]._type !== 'side'
      );
    } else if (sideWithSideItem.length === 2) {
      if (sideWithSideItem[0] === OPPOSITE_SEAT_SIDES[sideWithSideItem[1]]) {
        // the two sides are connect to the opposite direction. Again, not a usual setup, if world top are connect with side, then add pillow to that side, otherwise to the right
        configVal = this[this.getLocalSide('top')]
          ? this.getLocalSide('top')
          : this.getLocalSide('right');
      } else {
        // the seat is used as a corner piece, or in the other word, the two side are form a corner
        // we will then need to consider the sides of its adject seat and based on the shape of the sides, deside how to add pillows
        // 1. there are no other sides "connected" (not actually connect, but positioned next to each other because of the adject seat) to any of the two sides of this seat
        //    in this case, we consider the two sides of this seat form a free stand corner, the pillow will be added follow the world top -> right -> left -> bottom order
        // 2. one of the side "connected" to a third sides, this means the two sides of this seat form a end corner, the pillow need to be added to the side that 'connect' to the third side
        // 3. both sides are "connected" to other sides, this means the two sides of this seat form a regular corner, the pillow will be again follow the order as case 1
        const [side1, side2] = sideWithSideItem;
        const [side1World, side2World] = sideWithSideItem.map(
          this.getWorldSide
        );

        let side1ConnectedSide;
        let side2ConnectedSide;

        try {
          side1ConnectedSide =
            this.getConnectedItemWithWorldSide(
              OPPOSITE_SEAT_SIDES[side2World]
            ).getConnectedItemWithWorldSide(side1World)._type === 'side';
        } catch (e) {}

        try {
          side2ConnectedSide =
            this.getConnectedItemWithWorldSide(
              OPPOSITE_SEAT_SIDES[side1World]
            ).getConnectedItemWithWorldSide(side2World)._type === 'side';
        } catch (e) {}

        // xor, for case 2
        if (side1ConnectedSide ? !side2ConnectedSide : side2ConnectedSide) {
          configVal = side1ConnectedSide ? side1 : side2;
        } else {
          configVal = this.getLocalSide(
            ['top', 'right', 'left', 'bottom'].find((worldSide) => {
              const localSide = this.getLocalSide(worldSide);
              return localSide === side1 || localSide === side2;
            })
          );
        }
      }
    } else {
      // when only one or no side connected, it will just attach to that side or no pillow
      configVal = sideWithSideItem[0];
    }

    // if (
    //   sideWithSideItem.length &&
    //   (!this[configVal] || this[configVal]._type !== 'side')
    // ) {
    //   debugger;
    // }

    const connectAngledSide = /angled/i.test(this[configVal]?._key);

    const BackPillow =
      this[configVal] && !(connectAngledSide && this[configVal].style === 'arm')
        ? PILLOW_CONFIG_MAP[configVal]
        : 'none';

    let SidePillow = 'none';
    if (
      !(connectAngledSide && this[configVal].style === 'arm') &&
      !noDeepPillow
    ) {
      if (
        isCorner ||
        (sideWithSideItem.length === 2 &&
          !SIDE_KEYWORD.some(
            (side) => !this[side] || this[side]._type === 'anytable'
          )) // 2 sides and 2 seats attached to this item
      ) {
        const nextItem = this[NEXT_SEAT_SIDES[SIDE_CONFIG_MAP[BackPillow]]];
        const prevItem = this[PREVIOUS_SEAT_SIDES[SIDE_CONFIG_MAP[BackPillow]]];
        if (
          nextItem?._type === 'side' &&
          !(/angled/.test(nextItem?._key) && nextItem.style === 'arm')
        ) {
          SidePillow = 'right';
        } else if (
          prevItem?._type === 'side' &&
          !(/angled/.test(prevItem?._key) && prevItem.style === 'arm')
        ) {
          SidePillow = 'left';
        }
      }
    }

    // set seat's side pillows
    if (SidePillow !== 'none') {
      this.pillows.push({
        type: 'backPillow',
        key: 'deep',
      });
    }

    this._configurator.setConfiguration({
      BackPillow,
      PillowBack: connectAngledSide ? 'Angled' : 'Standard',
      SidePillow,
    });
  };

  // validation of the options parameter
  _validOptions = (options) => {
    if (options.connector && options.translation !== undefined)
      console.warn(
        'You are initial the item with both translation/rotation and connector, the translation/rotation will be overwrite by connection result'
      );
  };

  // rotation range and idx map for each implement rotation related alignment
  // [0,90) -> 0
  // [90, 180) -> 1
  // [180, 270) -> 2
  // [270, 360) -> 3
  _getRotationIdx = () => Math.floor(this.rotation / 90);

  validateConnection = (item) => true;

  // this function will behavious different in different sactional piece class, based on the available local side of that class
  getWorldSide = (localSide) => {
    const worldSide = getWorldKeyword(
      localSide,
      this._getRotationIdx(),
      SIDE_KEYWORD,
      (key) => `Unknown side ${key}`
    );

    return this.hasOwnProperty(localSide) ? worldSide : null;
  };

  // similarly this function will behavious different in different sactional piece class, based on if the localside exist or not
  getLocalSide = (worldSide) => {
    const localSide = getLocalKeyword(
      worldSide,
      this._getRotationIdx(),
      SIDE_KEYWORD,
      (key) => `Unknown side ${key}`
    );
    return this.hasOwnProperty(localSide) ? localSide : null;
  };

  getLocalSideDimension = (side) => {
    if (!this.width || !this.height) {
      throw new Error(
        'You are calling an absolute method, please overwirte it or define the width and height in the extend class'
      );
    }
    if (!this.hasOwnProperty(side)) return null;

    return this[SIDE_DIMENSION_MAP[side]];
  };

  getConnectedItemWithWorldSide = (worldSide) => {
    const thisSideLocal = this.getLocalSide(worldSide);
    if (!this.hasOwnProperty(thisSideLocal)) return null;
    return this[thisSideLocal];
  };

  _getLocalSideWorldRotation = (localSide) => {
    if (!this.hasOwnProperty(localSide)) {
      throw new Error(`Item does not have side ${localSide}!`);
    }
    const sideIdx = SIDE_KEYWORD.indexOf(localSide);

    const rotation = this.rotation + (this[`_${localSide}Rotation`] || 0);

    return mapRotationInRange(rotation + 90 * sideIdx);
  };

  // return the rotation to match the specific side of this item, to the specific local side of the target item
  _getRotationFromSideConnection = (thisLocalSide, target, targetLocalSide) => {
    const thisSideRotation = this._getLocalSideWorldRotation(thisLocalSide);
    const targetSideRotation = target._getLocalSideWorldRotation(
      targetLocalSide
    );

    // 180 to make sure the this side are align in the opposite direction as the target side for connection
    return mapRotationInRange(
      this.rotation + targetSideRotation - thisSideRotation - 180
    );
  };

  _getTranslationFromVertexConnection = (
    targetVertexTransaltion,
    thisVertexTransaltion
  ) => {
    const newTrans = { ...this.translation };
    ['x', 'y', 'z'].forEach((axis) => {
      newTrans[axis] +=
        targetVertexTransaltion[axis] - thisVertexTransaltion[axis];
    });
    return newTrans;
  };

  _getVertexLocalTranslation = (localPosition, adjustVal = 0) => {
    if (!this.width || !this.height) {
      throw new Error(
        'You are calling an absolute method, please overwirte it or define the width and height in the extend class'
      );
    }

    const width = this.width - adjustVal;
    const height = this.height - adjustVal;

    let xLocalOffset;
    let zLocalOffset;
    switch (localPosition) {
      case 'topLeft':
        xLocalOffset = -width / 2;
        zLocalOffset = -height / 2;
        break;
      case 'topRight':
        xLocalOffset = width / 2;
        zLocalOffset = -height / 2;
        break;
      case 'bottomRight':
        xLocalOffset = width / 2;
        zLocalOffset = height / 2;
        break;
      case 'bottomLeft':
        xLocalOffset = -width / 2;
        zLocalOffset = height / 2;
        break;
      default:
    }

    return { x: xLocalOffset, y: 0, z: zLocalOffset };
  };

  // pass the world position of vertex, and return the world translation of that vertex
  // this method should be only used in world space, and not use together with any local space method
  getVertexWorldTranslation = (position, adjustVal = 0) => {
    if (!this.width || !this.height) {
      throw new Error(
        'You are calling an absolute method, please overwirte it or define the width and height in the extend class'
      );
    }

    const localTranslation = this._getVertexLocalTranslation(
      position,
      adjustVal
    );

    return this.getWorldTranslation(localTranslation);
  };

  // this will return the translation of the two vertices of the target edge in array used for alignment calculation
  // when top alignment between two edges, means the first vertex must match
  // for bottom alignment, means the second vertex should match

  _getSideVerticesTranslation = (side) => {
    const nodePosition = SIDE_VERTICES_MAP[side];
    if (!nodePosition) throw new Error(`Unknown side ${side}`);

    return nodePosition.map((position) =>
      this.getVertexWorldTranslation(position)
    );
  };

  setRotation = (newRotation) => {
    const updateRotation = mapRotationInRange(newRotation);
    if (updateRotation === this.rotation) return;
    this.rotation = updateRotation;
    if (this._id) {
      this._threekitApi.scene.set(
        { id: this._id, plug: 'Transform', property: 'rotation' },
        { x: 0, y: -updateRotation, z: 0 }
      );
    }
  };

  setTranslation = (newTranslation) => {
    this.translation = newTranslation;
    if (this._id) {
      this._threekitApi.scene.set(
        { id: this._id, plug: 'Transform', property: 'translation' },
        newTranslation
      );
    }
  };

  // map the world translation to the localtion translation of this item
  getLocalTranslation = (worldTranslation) => {
    const world = new this._threekitApi.THREE.Vector3(
      worldTranslation.x - this.translation.x,
      worldTranslation.y - this.translation.y,
      worldTranslation.z - this.translation.z
    );

    const transformMatrix = new this._threekitApi.THREE.Matrix4().makeRotationY(
      (this.rotation / 180) * Math.PI
    );

    return world.applyMatrix4(transformMatrix);
  };

  getWorldTranslation = (localTranslation) => {
    const local = new this._threekitApi.THREE.Vector3(
      localTranslation.x,
      localTranslation.y,
      localTranslation.z
    );

    const thisTranslation = new this._threekitApi.THREE.Vector3(
      this.translation.x,
      this.translation.y,
      this.translation.z
    );

    const transformMatrix = new this._threekitApi.THREE.Matrix4()
      .makeRotationY((-this.rotation / 180) * Math.PI)
      .setPosition(thisTranslation);

    return local.applyMatrix4(transformMatrix);
  };

  intersectWith = (targetItem) => {
    // first check the bounding sphere's overlay
    const disSq = Math.sqrt(
      (targetItem.translation.x - this.translation.x) ** 2 +
        (targetItem.translation.z - this.translation.z) ** 2
    );
    const minNonintersectDisSq =
      Math.sqrt((targetItem.width / 2) ** 2 + (targetItem.height / 2) ** 2) +
      Math.sqrt((this.width / 2) ** 2 + (this.height / 2) ** 2);

    if (disSq >= minNonintersectDisSq) return false;

    const thisVertices = VERTEX_KEYWORD.map((position) =>
      this.getVertexWorldTranslation(position, FLOAT_POINT_ADJUST)
    ).map((trans) => [trans.x, trans.z]);
    const targetVertices = VERTEX_KEYWORD.map((position) =>
      targetItem.getVertexWorldTranslation(position, FLOAT_POINT_ADJUST)
    ).map((trans) => [trans.x, trans.z]);

    return polygonIntersect(thisVertices, targetVertices);
  };

  getPlusSignTranslation = (localSide, offset = 0) => {
    if (!this.width || !this.height)
      throw new Error(
        'You are calling an absolute method, please overwirte it or define the width and height in the extend class'
      );

    let dx = 0;
    let dz = 0;

    const distance = PLUS_SIGN_DISTANCE + offset;

    switch (localSide) {
      case 'left':
        dx = -this.width / 2 - distance;
        break;
      case 'right':
        dx = this.width / 2 + distance;
        break;
      case 'top':
        dz = -this.height / 2 - distance;
        break;
      case 'bottom':
        dz = this.height / 2 + distance;
        break;
      default:
        throw new Error(`Unknown side ${localSide}`);
    }

    return this.getWorldTranslation({ x: dx, y: 0, z: dz });
  };

  // initial the class by create threekit instance
  init = async () => {
    if (this._id) return;

    const rootSceneId = await getRootId(this._threekitApi);
    const parentId = this._threekitApi.scene.findNode({
      from: rootSceneId,
      type: 'Objects',
    });

    const modelId = this._threekitApi.scene.addNode(
      {
        name: `ConfigModel_${this._type}_${this._key}`,
        type: 'Model',
        plugs: {
          Null: [
            {
              type: 'Model',
              asset: {
                query: {
                  metadata: {
                    itemType: this._type,
                    itemKey: this._keyAlians || this._key,
                  },
                },
              },
            },
          ],
          Transform: [
            {
              type: 'Transform',
              translation: this.translation,
              rotation: { x: 0, y: -this.rotation, z: 0 },
            },
          ],
        },
      },
      parentId
    );
    this._id = modelId;
    this._configurator = await this._threekitApi.player.getConfiguratorInstance(
      { id: modelId, plug: 'Null', property: 'asset' }
    );
    this._updatePillow();

    // to do: decide if we need configuration instance here
  };

  // the item node will contain circle structure so it is not possible to be stringified directly
  // this function reture an object that could be pass to JSON.stringify
  toJsonObj = () => {
    return Object.entries(this).reduce((jsonObj, [key, val]) => {
      if (typeof val === 'function' || key === '_threekitApi') return jsonObj;

      switch (key) {
        case '_threekitApi':
        case '_configurator':
        case 'connectors':
          break;
        case '_id':
          jsonObj[key] = this.getInstanceId(); // use this API instead of access directly from _id to make sure the initial validation check
          break;
        case 'left':
        case 'right':
        case 'top':
        case 'bottom':
          jsonObj[key] = val && val.getInstanceId();
          break;
        default:
          jsonObj[key] = val;
      }
      return jsonObj;
    }, {});
  };

  // this function will not take care of connection setup and should be used in Island class only
  fromJsonObj = (jsonObj) => {
    if (this._id) {
      throw new Error(
        'fromJsonObj can not be called after item initialization'
      );
    }
    const obj = typeof jsonObj === 'string' ? JSON.parse(jsonObj) : jsonObj;

    // this is a temp fix by removing the corrupt field as it will not cause any issues
    // need further investage when saved json has there two field
    if (obj.hasOwnProperty('undefined')) delete obj.undefined;
    if (obj.hasOwnProperty('undefinedAlign')) delete obj.undefinedAlign;

    Object.keys(obj).forEach((key) => {
      if (!this.hasOwnProperty(key))
        console.warn(`Invalid input data, unknow property ${key}!`);

      switch (key) {
        case 'left':
        case 'right':
        case 'top':
        case 'bottom':
        case '_id':
          break;
        default:
          this[key] = obj[key];
      }
    });
  };

  getInstanceId = () => {
    this._initialCheck();
    return this._id;
  };

  getConnections = () =>
    SIDE_KEYWORD.filter((side) => {
      return !!this[side];
    }).map((localSide) => ({
      localSide,
      // worldSide: this.getWorldSide(localSide),
      alignment: this[getAlignKeyword(localSide)],
      target: this[localSide],
      targetLocalSide: SIDE_KEYWORD.find(
        (side) => this[localSide][side] === this
      ),
    }));

  /**
   * For an incoming item, get a list of connectors that this item is open for
   * connection
   * @param {object} item
   * @param {object} options
   * @returns
   */
  getConnectors = (item, options) => [];

  // connect this item's specific side(localSide) to the target item's(target) specific side(targetLocalSide) with the given alignment
  _connectHelper = (connector) => {
    const { target, targetLocalSide, alignment, localSide } = connector;

    let alignValue = alignment || 'top';

    // update target side reference
    target[targetLocalSide] = this;
    target[getAlignKeyword(targetLocalSide)] = alignValue;

    // update this side reference
    this[localSide] = target;
    this[getAlignKeyword(localSide)] = ALIGNMENT_MAP[alignValue];
    this.island = target.island;

    // update this rotation
    const newRotation = this._getRotationFromSideConnection(
      localSide,
      target,
      targetLocalSide
    );
    this.setRotation(newRotation);

    // update this translation
    let targetVerticeTrans = target._getSideVerticesTranslation(
      targetLocalSide
    );

    // due to the normal of the two connect edge always has 180 degree rotation different, a reverse need to apply
    let thisVerticeTrans = this._getSideVerticesTranslation(
      localSide
    ).reverse();

    // When anytable connects to longer side of a seat, and seat's shorter
    // side connects to a Side, align the edge of anytable to Side's back
    if (
      this._type === 'seat' &&
      target?._type === 'anytable' &&
      (localSide === 'top' || localSide === 'bottom')
    ) {
      const findBackSide = ['left', 'right'].find((side) => {
        return (
          this[side]?._type === 'side' &&
          this[OPPOSITE_SEAT_SIDES[side]]?._type !== 'side'
        );
      });
      if (findBackSide) {
        const side = this[findBackSide];
        // temporay solustion for shifting
        this.width += side.height * 2;
        thisVerticeTrans = this._getSideVerticesTranslation(
          localSide
        ).reverse();
        this.width -= side.height * 2;
        if (NEXT_SEAT_SIDES[localSide] === findBackSide) {
          alignValue = 'top';
        } else {
          alignValue = 'bottom';
        }
      }
    } else if (
      this._type === 'anytable' &&
      target?._type === 'seat' &&
      (targetLocalSide === 'top' || targetLocalSide === 'bottom')
    ) {
      const findBackSide = ['left', 'right'].find((side) => {
        return (
          target[side]?._type === 'side' &&
          target[OPPOSITE_SEAT_SIDES[side]]?._type !== 'side'
        );
      });
      if (findBackSide) {
        const side = target[findBackSide];
        target.width += side.height * 2;
        targetVerticeTrans = target._getSideVerticesTranslation(
          targetLocalSide
        );
        target.width -= side.height * 2;
        if (NEXT_SEAT_SIDES[targetLocalSide] === findBackSide) {
          alignValue = 'bottom';
        } else {
          alignValue = 'top';
        }
      }
    }

    // translation of the vertex based on alignment
    const alignmentIdx = alignValue === 'top' ? 0 : 1;

    const newTrans = this._getTranslationFromVertexConnection(
      targetVerticeTrans[alignmentIdx],
      thisVerticeTrans[alignmentIdx]
    );

    this.setTranslation(newTrans);
  };

  getBoundingBox = () => {
    const vertexTranslation = VERTEX_KEYWORD.map((position) =>
      this.getVertexWorldTranslation(position)
    );
    const box = new this._threekitApi.THREE.Box3();
    box.min.y = 0;
    box.max.y = 0;

    vertexTranslation.forEach((translation) => {
      box.min.x = Math.min(box.min.x, translation.x);
      box.min.z = Math.min(box.min.z, translation.z);
      box.max.x = Math.max(box.max.x, translation.x);
      box.max.z = Math.max(box.max.z, translation.z);
    });

    return box;
  };
}
