import Configurator from './classes/Configurator';
import { postConfiguration } from './threekitUtils';
import {
  DEFAULT_SCENE_STATE,
  mapSceneConfigurationToTKConfiguration,
} from '../v1/modules/room';
import { getThumbnail } from '../v2/vray/thumbnail';
import { exportAR } from '../v2/platform';
import { status } from '../status';

/* global __THREEKIT_AUTH_TOKEN__ */

export async function init(params) {
  const { el, initialJson, device, analyticsCustomId } = params;

  let threekitConfiguration;
  let sactionalConfiguration;
  if (initialJson) {
    const initConfig = JSON.parse(initialJson);
    threekitConfiguration = initConfig.threekitConfiguration;
    sactionalConfiguration = initConfig.sactionalConfiguration;
  }

  const threekitApi = await window.threekitPlayer({
    el,
    authToken: __THREEKIT_AUTH_TOKEN__,
    assetId: '4ccd4c4c-8a92-4168-90bf-79d88c028199',
    initialConfiguration:
      threekitConfiguration ||
      mapSceneConfigurationToTKConfiguration(DEFAULT_SCENE_STATE),
    showConfigurator: false,
    display: 'webgl',
    // publishStage: 'draft',
    analyticsCustomId,
    // For solving an issue with area light caused by the default webgl2
    webglContextType: 'webgl',
  });

  threekitApi.on('loaded', () => console.log('threekit player loaded'));

  threekitApi.enableApi('player');

  // const configInstance = new Configurator(threekitApi);
  // const configInstance = new Configurator(threekitApi, {
  //   islands: [new Island(threekitApi, [new Seat(threekitApi)])],
  //   playerEl: el,
  // });
  const configInstance = new Configurator(threekitApi, {
    // islands: [new Island(threekitApi, testData(threekitApi))],
    playerEl: el,
    device,
  });
  window.configInstance = configInstance;

  // Initial camera is ortho, we want to disable zoom actions
  // by user but don't want to limit zoom ability

  const api = {
    api: threekitApi,
    threekitEl: el,
    configurator: {
      setFloor: configInstance.setFloor,
      setFabric: configInstance.setFabric,
      getItems: () =>
        configInstance
          ._getAllItems()
          .reduce(
            (res, item) => Object.assign(res, { [item.getInstanceId()]: item }),
            {}
          ),
      toggleMeasurement: configInstance.toggleMeasurement,
      setStealthTech: configInstance.setStealthTech,
      setSubwoofers: configInstance.setSubwoofers,
      checkEligibility: configInstance.checkEligibility,
      rotateCamera: (view) => {
        // console.log('Implement webgl rotateCamera here');
      },
      getPlayerLogo: () => getThreekitLogo(el),
      getAR: async (options = {}) => {
        const { id, url } = options;
        if (id && url) {
          const result = await exportAR(options, 'v1');
          return result;
        } else {
          const Layout = configInstance.getLayoutJson();
          const config = configInstance._configurator.getConfiguration();
          config.Layout = Layout;
          const result = await exportAR({ configuration: config }, 'v1');
          return result;
        }
      },
      getThumbnail,
      saveConfiguration: async () => {
        const { id } = await postConfiguration(configInstance.toJson());
        return id;
      },
      deleteItem: configInstance.deleteItem,
      getSelectItem: configInstance.getSelectedItem,
      getItemById: configInstance.getItemById,
      incrementSelectItemRotation: configInstance.incrementItemRotation,
      displayPlusSign: (itemType, itemKey) => {
        configInstance.renderPlusSign(itemType, itemKey);
      },
      hidePlusSign: configInstance.clearPlusSign,
      setCameraView: configInstance.setCameraView,
      setProduct: configInstance.setProduct,
      setBackType: configInstance.setBackType,
      setArmType: configInstance.setArmType,
      setWood: configInstance.setWood,
      isPresetLayout: configInstance.isPresetLayout,
      addEventListener: (name, handler) =>
        window.addEventListener(name, handler),
      getItemCount: () => {
        const count = {};
        const deepSeatSet = new Set();
        const deemedDeepSeats = configInstance.sets.reduce((acc, set) => {
          const { convertDeep } = set;
          if (convertDeep && convertDeep.length) {
            convertDeep.forEach((side) => {
              acc[side._id] = side;
            });
          }
          return acc;
        }, {});

        const updateCount = (type, key, change) => {
          const typeKey = `${type}-${key}`;
          const currentCount = count[typeKey] || 0;

          count[typeKey] = currentCount + change;
        };

        const { ottomans } = status;
        let spareOttomanPillows = Object.keys(ottomans).length;
        configInstance._getAllItems().forEach((item) => {
          const type = item._type;
          const key = item._keyAlians || item._key;
          updateCount(type, key, 1);
          if (
            type === 'side' &&
            (key.startsWith('deep') || deemedDeepSeats[item._id])
          ) {
            const seat = item.bottom;
            if (!deepSeatSet.has(seat)) {
              updateCount('seat', 'standard', -1);
              updateCount('seat', 'deep', 1);
              deepSeatSet.add(seat);
            }
          } else if (
            type === 'side' &&
            key === 'angled' &&
            item.style === 'arm'
          ) {
            updateCount('sidePillow', 'angled', 1);
          }
          if (item.pillows.length > 0) {
            // this item has an extra side pillow
            item.pillows.forEach((pillow) => {
              // get it from ottoman to display it
              if (spareOttomanPillows > 0) {
                spareOttomanPillows -= 1;
              } else {
                // add one to the cart
                const { type, key } = pillow;
                updateCount(type, key, 1);
              }
            });
          }
        });

        return Object.entries(count).map(([typeKey, itemCount]) => {
          const [type, key] = typeKey.split('-');
          return { type, key, count: itemCount };
        });
      },
      getSideStyles: () => {
        const allSides = configInstance
          ._getAllItems()
          .filter((ite) => ite._type === 'side');
        const armInfo = allSides.reduce((acc, side) => {
          const { _key, style } = side;
          if (!acc[style]) {
            acc[style] = new Set([]);
          }
          if (/rollarm/i.test(_key)) {
            if (!acc[style].has('RollArm')) {
              acc[style].add('RollArm');
            }
          } else if (/angled/i.test(_key)) {
            if (!acc[style].has('Angled')) {
              acc[style].add('Angled');
            }
          } else {
            // Standard
            if (!acc[style].has('Standard')) {
              acc[style].add('Standard');
            }
          }
          return acc;
        }, {});
        const ArmStyles = armInfo.arm
          ? Array.from(armInfo.arm.keys()).sort()
          : [];
        const BackStyles = armInfo.back
          ? Array.from(armInfo.back.keys()).sort()
          : [];

        return { ArmStyles, BackStyles };
      },
      getItemByTypeIndex: configInstance.getItemByTypeIndex,
      selectItem: configInstance.selectItem,
      addToItemBySide: configInstance.addToItemBySide,
      getConfiguration: configInstance.getConfiguration,
      toJson: configInstance.toJson,
    },
  };

  // window.addEventListener('itemSelect', (e) => {
  //   console.log(`itemSelect emit with select item id ${e.detail.id}`);
  // });
  // window.addEventListener('itemAdd', (e) => {
  //   console.log(`itemAdd emit with add item id ${e.detail.id}`);
  // });
  // window.addEventListener('itemDelete', (e) => {
  //   console.log(`itemDelete emit with delete item id ${e.detail.id}`);
  // });
  // window.addEventListener('itemDeleteError', (e) => {
  //   console.log(
  //     `itemDeleteError emit with delete item id ${e.detail.id}, and error message ${e.detail.error}`
  //   );
  // });
  // window.addEventListener('convertSide', (e) => {
  //   console.log(
  //     `convertSide emit with delete item id ${e.detail.prevSideId} with key ${e.detail.prevSideType}, and new item ${e.detail.newSideId} with key ${e.detail.newSideType}`
  //   );
  // });
  // window.addEventListener('itemMoveStart', (e) => {
  //   console.log(`itemMoveStart emit with item id ${e.detail.id}`);
  // });
  // window.addEventListener('itemMoveEnd', (e) => {
  //   console.log(
  //     `itemMoveEnd emit with item id ${e.detail.id} and target item id it connect to ${e.detail.connectItemId} `
  //   );
  // });

  await configInstance.initThreekit();
  // await configInstance.fromJson(testJson);

  if (sactionalConfiguration) {
    await configInstance.fromJson(initialJson);
  }

  // move logo to bottom right
  moveLogo(el).catch((err) => {
    console.log('Error encountered moving logo url', err);
  });
  removeWatermark(el);
  return api;
}

const getThreekitLogo = (el) => {
  const elems = Array.from(el.getElementsByTagName('div')).filter(
    (ele) => ele.className.includes('logo') && ele.className.includes('button')
  );

  return elems[0];
};

async function moveLogo(parentElem) {
  const startTime = new Date();

  const check = () => {
    const logo = getThreekitLogo(parentElem);
    if (!logo) {
      if (new Date() - startTime < 120000)
        return new Promise((resolve) => setTimeout(resolve, 50)).then(check);
    } else {
      logo.setAttribute('style', 'top:auto;bottom:8px;left:auto;right:50px');
    }
  };
  return check();
}

async function removeWatermark(parentElem) {
  const startTime = new Date();

  const check = () => {
    const waterMark = Array.from(parentElem.getElementsByTagName('div')).filter(
      (ele) => {
        return ele.className.includes('waterMark');
      }
    )[0];

    if (!waterMark) {
      if (new Date() - startTime < 120000)
        return new Promise((resolve) => setTimeout(resolve, 50)).then(check);
    } else {
      // document.getElementsByTagName('div')[0].re
      waterMark.remove();
    }
  };
  return check();
}
