const state = {
  //   width: 0,
  //   depth: 0,
  //   height: 0,
  //   seating: 0,
  //   backPillowsUsed: 0,
  maxReclineSeats: 0,
  maxSubwoofers: 0,
  stealthTechEligible: [],
  stealthTechErrors: undefined,
};

export function updateCapacity(data) {
  const { items, bundles, added, maxReclineSeats } = data;
  let capacityChanged = false;

  if (state.maxReclineSeats !== maxReclineSeats) {
    state.maxReclineSeats = maxReclineSeats > 0 ? maxReclineSeats : 0;
    capacityChanged = true;
  }

  if (bundles) {
    // Detect what stealthTech bundle is eligble
    const { stealthTechEligible, stealthTechErrors, maxSubwoofers } = bundles;
    if (stealthTechErrors !== state.stealthTechErrors) {
      state.stealthTechErrors = stealthTechErrors;
      capacityChanged = true;
    }
    if (state.maxSubwoofers !== maxSubwoofers) {
      state.maxSubwoofers = maxSubwoofers;
      capacityChanged = true;
    }
    if (
      state.stealthTechEligible.length !== stealthTechEligible.length ||
      (stealthTechEligible.length === 2 &&
        stealthTechEligible[1].key !== state.stealthTechEligible[1].key)
    ) {
      state.stealthTechEligible = stealthTechEligible;
      capacityChanged = true;
    } else if (
      JSON.stringify(state.stealthTechEligible) !==
      JSON.stringify(stealthTechEligible)
    ) {
      state.stealthTechEligible = stealthTechEligible;
      capacityChanged = true;
    }
  }

  console.log('new capacity state', state);
  if (capacityChanged) return { ...state };
}
